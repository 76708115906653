.title_container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    z-index: 99;
    padding: 0 2rem 0 2rem;
}

.title{
    color: #172A6E;
    font-size: 3.2rem;
    font-family: 'Inter',sans-serif;
    font-weight: 700;
}
.text{
    color: #172A6E;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: 'Inter',sans-serif;
}

.main_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem 10rem 2rem;
    z-index: 99;
}

.card_1{
    width: 100%;
    height: 16rem;
    border-radius: 2rem;
    background: url("../staticImages/parent_bg1.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: 0 -80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 4rem;
    padding: 2rem 2rem 1rem 2rem;
    cursor: pointer;
}
.card_2{
    width: 100%;
    height: 16rem;
    border-radius: 2rem;
    background: url("../staticImages/parent_bg2.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: 0 -80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 4rem;
    padding: 2rem 2rem 1rem 2rem;
    cursor: pointer;
}
.card_3{
    width: 100%;
    height: 16rem;
    border-radius: 2rem;
    background: url("../staticImages/parent_bg3.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: 0 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 4rem;
    padding: 2rem 2rem 1rem 2rem;
    cursor: pointer;
}
.play_btn{
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.4px solid white;
    border-radius: 1rem;
}

.icon{
    color: white;
    font-size: 1.5rem;
}

.card_description{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
}

.age{
    color: white;
    font-size: 1.3rem;
    font-family: 'Inter',sans-serif;
}
.name{
    color: white;
    font-size: 2rem;
    font-family: 'Inter',sans-serif;
    font-weight: 700;
}