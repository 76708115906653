.message_container{
    padding: 20px 10px 20px 10px;
    width: 350px;
    min-height: 50px;
    background-color: #9bd9f4;
    border: 1px solid #019FE3;
    border-left: 3px solid #172A6E;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%,-25%);
}

.text {
font-size: 20px;
color: #343A40;
font-family: 'Inter',sans-serif;
font-weight: 500;
padding: 0;
margin: 0;
}

.btn{
    width: 100px;
    padding: 10px;
    background-color: #172A6E;
    border: none;
    outline: none;
    border-radius: 2px;
    font-size: 18px;
    font-family: 'Inter',sans-serif;
    color: white;
}