.line{
    width: 100%;
    height: 0.3rem;
    background-color: #A5CBF91A;
}

.btn_container{
    margin: 2rem 0 2rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn{
    width: 32rem;
    background-color: #30A2FF;
    border: none;
    outline: none;
    height: 4.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.2rem;
    font-size: 2rem;
    color: white;
    font-weight: 700;
    font-family: 'Poppins',sans-serif;
    cursor: pointer;
}

.padding{
    width: 100%;
    padding: 1rem 1rem 10rem 1rem;
}


.unsubscribe_container{
    border: 0.3rem solid #FF465A;
    width:100%;
    border-radius: 2rem;
    min-height: 20rem;
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.image_text_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.text_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
}

.text{
    color: #172A6E;
    font-size: 2rem;
    font-family: 'Inter',sans-serif;
}

.p{
    color: #172A6E;
    font-size: 1.5rem;
    font-family: 'Inter',sans-serif;
}

.btn2{
    background-color: #D70A32;
}