.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.modal{
    padding: 1rem;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.danger_image{
    width: 60px;
    height: 60px;
}
.danger{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.text{
    text-align: center;
    font-size: 1.6rem;
    color: black;
    font-family: 'Inter',sans-serif;
    font-weight: 600;
}

.btns_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.btn{
    width: 100px;
    padding: 1rem;
    border: none;
    outline: none;
    background-color: rgb(216, 35, 35);
    color: white;
    font-size: 1.5rem;
    font-family: 'Inter',sans-serif;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.no{
    background-color: rgb(18, 224, 35);
}