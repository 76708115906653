.image_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 8rem;
    z-index: 99;
}
.image{
    width: 25rem;
    height: auto;
    object-fit: contain;
}

.name_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.name{
    margin-top: 2rem;
    width: 16rem;
    height: auto;
    object-fit: contain;
    z-index: 99;
}

.text{
    margin-top: 4rem;
    z-index: 99;
    width: 100%;
    text-align: center;
    padding: 0 1rem 0 1rem;
    font-size: 2rem;
    color: #2B368A;
    font-weight: 400;
    font-family: 'Inter',sans-serif;
    line-height: 2.6rem;
}