.topLines{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}
.line1{
    overflow: hidden;
    width: 100%;
    height: 1.5rem;
    background: #172A6E;
}
.line2{
    overflow: hidden;
    width: 100%;
    height: 1.3rem;
    background-color: #019fE3;
}