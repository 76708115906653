*,*::before,*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
html{
  font-size: 62.5%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button{
  cursor: pointer;
}

table{
  border: none !important;
  border-collapse: collapse !important;
  width: 100% !important;
}

td{
  width: 155.45pt !important;
  border: 1pt solid black !important;
  padding: 0cm 5.4pt !important;
  height: 15pt !important;
  vertical-align: bottom !important;
}