.accordian{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    z-index: 200;
    padding: 0 0 2rem 0;
}

.accordian_last{
    padding:  0 0 10rem 0;
}

.accordian_nav{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.nav_container{
    padding: 0 1rem 0 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav_sub_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.white_box{
    width: 25px;
    height: 25px;
    border-radius: 2px;
    background-color: white;
}

.title{
    font-size: 17px;
    color: #009FE3;
    font-weight: 600;
    font-family: 'Inter',sans-serif;
}

.icon{
    color: #C8C7CC;
    font-size: 16px;
    cursor: pointer;
}

.underline_container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0 0 4rem;
}

.underline{
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #C8C7CC;
}

.accordian_details{
    /* width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 2rem 0 2rem; */
    display: none;
    opacity: 0;
}

.accordian_details.show{
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 2rem 0 2rem;
    /* display: block; */
    display: flex;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.text{
    text-align: left;
    color: black;
    font-size: 15px;
    font-family: 'Inter',sans-serif;
    font-weight: 400;
}