.container{
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(to right,#00000075,#00000083);
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease-in-out forwards;
    z-index: 500;
    cursor: pointer;
}
.sub_container{
    width: 25rem;
    min-height: 20rem;
    background-color: white;
    z-index: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    overflow: hidden;
    padding: 3rem 2rem 3rem 2rem;
    border-radius: 0.3rem;
    box-shadow: 0 1rem 2rem rgba(0,0,0,0.6);
}
.info{
    width: 8rem;
    height: auto;
    object-fit: contain;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.msg{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text{
    width: 100%;
    text-align: center;
    color: black;
    font-size: 1.8rem;
    font-family: 'Inter',sans-serif;
}

.btns_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    /* flex-wrap: wrap; */
}

.btn{
    padding: 1rem 2rem 1rem 2rem;
    background-color: rgb(0, 75, 98);
    font-size: 1.5rem;
    font-family: 'Poppins',sans-serif;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    color: white;
    box-shadow: 0 1rem 1rem rgba(0,0,0,0.6);
    transition: all 0.3s;
}

.btn:hover{
    background-color: rgb(0, 47, 61);
}

.btn:active{
    transform: scale(1.1);
}

.btn_2{
    padding: 1rem 2rem 1rem 2rem;
    background-color: rgb(225, 10, 236);
    font-size: 1.5rem;
    font-family: 'Poppins',sans-serif;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    color: white;
    box-shadow: 0 1rem 1rem rgba(0,0,0,0.6);
    transition: all 0.3s;
}

.btn_2:hover{
    background-color: rgb(170, 0, 179);
}

.btn_2:active{
    transform: scale(1.1);
}


@keyframes fadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}