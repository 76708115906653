.header{
    width: 100%;
}
.header_sub_container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    background-color: #E5E5E5;
    padding: 2rem 0 2rem 0;
}
.tab{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
}
.text{
    font-size: 1.5rem;
    color: #172A6E;
    font-family: 'Inter',sans-serif;
    font-weight: 500;
}